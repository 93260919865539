// Home.js
import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import ProgressBar from './components/ProgressBar';
import ImageContainer from './components/ImageContainer';
import SettingsPanel from './SettingsPanel';
import { traduction, generateImage, checkStatus, checkCredits, saveGeneratedImage } from './services/apiService';
import './css/Home.css';

function Home() {
  const { user } = useAuth();
  const [isGenerating, setIsGenerating] = useState(false);
  const [result, setResult] = useState('');
  const [inputText, setInputText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState({
    style: '3D',
  });

  const pollStatus = async (jobId) => {
    let statusData;
    let attempts = 0;
    const maxAttempts = 50;

    do {
      statusData = await checkStatus(jobId);
      attempts++;
      
      if (statusData.status === 'IN_QUEUE') {
        setProgress(Math.min(25, (attempts / maxAttempts) * 100));
      } else if (statusData.status === 'IN_PROGRESS') {
        setProgress(Math.min(75, 25 + (attempts / maxAttempts) * 75));
      }

      if (statusData.status !== 'COMPLETED') {
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    } while (statusData.status !== 'COMPLETED' && attempts < maxAttempts);

    if (statusData.status === 'COMPLETED') {
      setProgress(100);
      if (statusData.output && statusData.output.images && statusData.output.images[0]) {
        const imageData = 'data:image/png;base64,' + statusData.output.images[0];
        setImageUrl(imageData);
        
        try {
          await saveGeneratedImage(imageData, user.username, inputText);
          console.log("Image sauvegardée sur le serveur");
        } catch (error) {
          console.error("Erreur lors de la sauvegarde de l'image sur le serveur:", error);
        }
      } else {
        setResult("Erreur: Aucune image n'a été générée.");
      }
    } else {
      setResult("La génération de l'image a pris trop de temps. Veuillez réessayer.");
    }
  };

  const handleGenerate = async () => {
    setIsGenerating(true);
    setResult('');
    setImageUrl('');
    setProgress(0);

    try {
      const checkCreditsResponse = await checkCredits(user.username);
      console.log(checkCreditsResponse);

      if (checkCreditsResponse === true) {
        const inputTextFr = await traduction(inputText);
        const jobId = await generateImage({ user: user.username, prompt: inputTextFr, style: settings.style });
        await pollStatus(jobId);
      } else {
        setResult("Vous n'avez plus de crédits.");
      }
    } catch (error) {
      console.error("Erreur lors de la génération:", error);
      setResult(`Une erreur s'est produite: ${error.message}`);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSettingsChange = (setting, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [setting]: value
    }));
  };

  return (
    <div className="home-container">
      <div className="content-wrapper">
        <p className='title'> <p className='lora-italic-500'>Imaginia</p></p>
        <p className='subtitle'><p className='lora-regular-400'>L'imagination comme limite.</p></p>
        <input 
          id="search_input" 
          type="text" 
          placeholder='Décrire une image'
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <div className="button-group">
          <button 
            id="search" 
            onClick={handleGenerate} 
            disabled={isGenerating || !inputText.trim()}
          >
            {isGenerating ? 'Génération en cours...' : 'Générer'}
          </button>
          <button 
            id="settings" 
            onClick={() => setShowSettings(!showSettings)}
          >
            Réglages
          </button>
        </div>
        <SettingsPanel 
          isOpen={showSettings}
          settings={settings}
          onSettingsChange={handleSettingsChange}
        />
        {isGenerating && <ProgressBar percentage={progress} />}
        {result && <div id='search_result' style={{whiteSpace: 'pre-line'}}>{result}</div>}
        <ImageContainer imageUrl={imageUrl} />
        {/* {user && <p className="credits-info">Crédits restants : {user.credits}</p>} */}
      </div>
    </div>
  );
}

export default Home;