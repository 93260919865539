import React from 'react';
import './css/Pricing.css';

const PricingPage = () => {
  return (
    <div className="pricing-container">
      <h1 className="pricing-title">Choisissez le forfait qui vous convient</h1>
      <h2 className="pricing-subtitle">... et commencez à créer des images uniques dès maintenant.</h2>
      <div className="pricing-table">
        <div className="pricing-plan">
          <h2 className="plan-title">Basique</h2>
          <p className="plan-price">
            <span className="price-amount">3€</span>
            <span className="price-duration">/mois</span>
          </p>
          <a href="https://buy.stripe.com/fZe3dA6y5eXb2l2dQV" className="cta-button">Commencer</a>
          <ul className="plan-features">
            <li className="nombre-creations">100 créations par mois</li>
            </ul>
            <ul className='plan-features-subtitle'>
              <li>Créations rapides</li>
              <li className='fonctionnalites-disables'>Styles personnalisés</li>
              <li className='fonctionnalites-disables'>Historique sauvegardé</li>
              <li className='fonctionnalites-disables'>Support prioritaire</li>
          </ul>
        </div>
        <div className="pricing-plan best-value">
          <h2 className="plan-title">Standard</h2>
          <p className="plan-price">
            <span className="price-amount">6€</span>
            <span className="price-duration">/mois</span>
          </p>
          <a href="https://buy.stripe.com/14k6pMcWt6qFe3K5kq" className="cta-button">Commencer</a>
          <ul className="plan-features">
            <li className="nombre-creations">200 créations par mois</li>
            </ul>
          <ul className="plan-features-subtitle">
            <li>Créations rapides</li>
            <li>Styles personnalisés</li>
            <li className="fonctionnalites-disables">Historique sauvegardé</li>
            <li className='fonctionnalites-disables'>Support prioritaire</li>
          </ul>
        </div>
        <div className="pricing-plan">
          <h2 className="plan-title">Pro</h2>
          <p className="plan-price">
            <span className="price-amount">10€</span>
            <span className="price-duration">/mois</span>
          </p>
          <a href="https://buy.stripe.com/eVa3dA8Gd6qFcZG6ov" className="cta-button">Nous contacter</a>
          <ul className="plan-features">
            <li className="nombre-creations">Créations Illimitées</li>
            </ul>
            <ul className="plan-features-subtitle">
              <li>Créations rapides</li>
              <li>Styles personnalisés</li>
            <li>Historique sauvegardé</li>
            <li>Support dédié 24/7</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;