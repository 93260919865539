// SettingsPanel.js
import React from 'react';
import './css/SettingsPanel.css';

function SettingsPanel({ isOpen, settings, onSettingsChange }) {
  return (
    <div className={`settings-container ${isOpen ? 'open' : ''}`}>
      <div className={`settings-panel ${isOpen ? 'open' : ''}`}>
        {/* <h3>Réglages de l'image</h3> */}
        {/* <div className="setting">
          {/* <label htmlFor="resolution">Résolution :</label>
          <select 
            id="resolution" 
            value={settings.resolution}
            onChange={(e) => onSettingsChange('resolution', e.target.value)}
          >
            <option value="512x512">512x512</option>
            <option value="1024x1024">1024x1024</option>
            <option value="2048x2048">2048x2048</option>
          </select> */}
        {/* </div> } */}
        <div className="setting">
          <label htmlFor="style">Style :</label>
          <select 
            id="style" 
            value={settings.style}
            onChange={(e) => onSettingsChange('style', e.target.value)}
          >
          <option value="Anime">Anime</option>
            <option value="Anime3D">Anime 3D</option>
            <option value="AnimeReal">Anime Réél</option>
            <option value="SoftAnime">Soft Anime</option>
            <option value="3D">3D</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default SettingsPanel;