import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './css/Navbar.css';
import { useAuth } from './AuthContext';

const Navbar = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <nav className="navbar">
            <ul className="navbar-list">
                <li className="navbar-item">
                    <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Accueil</Link>
                </li>
                <li className="navbar-item">
                    <Link to="/generate" className={location.pathname === '/generate' ? 'active' : ''}>Générer</Link>
                </li>
                <li className="navbar-item">
                    <Link to="/pricing" className={location.pathname === '/pricing' ? 'active' : ''}>Tarifs</Link>
                </li>
                <li className="navbar-item">
                    <Link to="/gallery" className={location.pathname === '/gallery' ? 'active' : ''}>Historique</Link>
                </li>
                <li className="navbar-item">
                    <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>A propos</Link>
                </li>
               <li className="auth-section">
    {user && (
        <>
            {/* <span className="user-info">
                Créations restantes : {user.credits}
            </span> */}
            <button onClick={handleLogout} className="auth-button">
                Déconnexion
            </button>
        </>
    )}
</li>
            </ul>
        </nav>
    );
}

export default Navbar;