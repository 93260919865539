// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Navbar from './Navbar';
import Home from './Home';
import Login from './Login';
import Pricing from './Pricing';
import PrivateRoute from './PrivateRoute';
import './App.css';
import RegisterPage from './Register';
import ImageGeneration from './ImageGeneration';
import About from './About';
import Gallery from './Gallery';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/services" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/generate" element={<PrivateRoute><ImageGeneration /></PrivateRoute>} />
            <Route path="/gallery" element={<PrivateRoute><Gallery /></PrivateRoute>} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;