import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import { checkCredits, traduction, generateImage, checkStatus, saveGeneratedImage } from './services/apiService';
import { useAuth } from './AuthContext';
import ProgressBar from './components/ProgressBar';
import './css/ImageGeneration.css';

const ImageGeneration = () => {
  const { user, updateCredits } = useAuth();
  const [prompt, setPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [sizeIndex, setSizeIndex] = useState(1);
  const [steps, setSteps] = useState(20);
  const [style, setStyle] = useState('3D');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  const sizesOptions = [
    { width: 512, height: 512 },
    { width: 512, height: 768 },
    { width: 768, height: 1024 },
    { width: 768, height: 1344 },

  ];

  const handleGenerate = async () => {
    setIsGenerating(true);
    setError(null);
    setProgress(0);
    setGeneratedImage(null);

    try {
      const checkCreditsResponse = await checkCredits(user.username);
      if (checkCreditsResponse === true) {
        const promptEn = await traduction(prompt);
        const negativePromptEn = await traduction(negativePrompt);
        const { width, height } = sizesOptions[sizeIndex];
        const jobId = await generateImage({user: user.username, prompt: promptEn, negative_prompt: negativePromptEn, width: width, height: height, steps: steps, style: style});
        await pollStatus(jobId);
      } else {
        setError('Vous n\'avez plus de crédits.');
        setIsGenerating(false);
      }
    } catch (err) {
      setError(err.message);
      setIsGenerating(false);
    }
  };

  const pollStatus = async (jobId) => {
    let statusData;
    let attempts = 0;
    const maxAttempts = 50;

    do {
      statusData = await checkStatus(jobId);
      attempts++;
      
      if (statusData.status === 'IN_QUEUE') {
        setProgress(Math.min(25, (attempts / maxAttempts) * 100));
      } else if (statusData.status === 'IN_PROGRESS') {
        setProgress(Math.min(75, 25 + (attempts / maxAttempts) * 75));
      }

      if (statusData.status !== 'COMPLETED') {
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    } while (statusData.status !== 'COMPLETED' && attempts < maxAttempts);

    if (statusData.status === 'COMPLETED') {
      setProgress(100);
      if (statusData.output && statusData.output.images && statusData.output.images[0]) {
        const imageData = 'data:image/png;base64,' + statusData.output.images[0];
        setGeneratedImage(imageData);
        
        try {
          await saveGeneratedImage(imageData, user.username, prompt);
          const newCredits = user.credits - 1;
          updateCredits(newCredits);
        } catch (error) {
          console.error("Erreur lors de la sauvegarde de l'image sur le serveur:", error);
        }
      } else {
        setError("Erreur: Aucune image n'a été générée.");
      }
    } else {
      setError("La génération de l'image a pris trop de temps. Veuillez réessayer.");
    }
    setIsGenerating(false);
  };

  return (
    <div className="image-generation">
      <div className="prompts-section">
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Décrivez l'image que vous souhaitez générer..."
          className="prompt-input"
        />
        <textarea
          value={negativePrompt}
          onChange={(e) => setNegativePrompt(e.target.value)}
          placeholder="Éléments à exclure de l'image..."
          className="negative-prompt-input"
        />
        <button 
          onClick={handleGenerate}
          disabled={isGenerating || !prompt}
          className="generate-button"
        >
          {isGenerating ? 'Génération en cours...' : 'Générer l\'image'}
        </button>
        {user && <p className="credits-info">Crédits restants : {user.credits}</p>}
      </div>

      <div className="settings-section">
        <div className="setting">
          <label>Taille de l'image</label>
          <ReactSlider
            className="slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            value={sizeIndex}
            onChange={setSizeIndex}
            min={0}
            max={sizesOptions.length - 1}
          />
          <span className="size-label">
            {`${sizesOptions[sizeIndex].width}x${sizesOptions[sizeIndex].height}`}
          </span>
        </div>
        <div className="setting">
          <label htmlFor="steps">Étapes</label>
          <input
            type="number"
            id="steps"
            value={steps}
            onChange={(e) => setSteps(Number(e.target.value))}
            min="10"
            max="30"
          />
        </div>
        <div className="setting">
          <label htmlFor="style">Style</label>
          <select
            id="style"
            value={style}
            onChange={(e) => setStyle(e.target.value)}
          >
            <option value="Anime">Anime</option>
            <option value="Anime3D">Anime 3D</option>
            <option value="AnimeReal">Anime Réél</option>
            <option value="SoftAnime">Soft Anime</option>
            <option value="3D">3D</option>
          </select>
        </div>
      </div>

      {isGenerating && <ProgressBar percentage={progress} />}

      {error && <p className="error-message">{error}</p>}
      
      {generatedImage && (
        <div className="generated-image-container">
          <img src={generatedImage} alt="Generated" className="generated-image" />
        </div>
      )}
    </div>
  );
};

export default ImageGeneration;