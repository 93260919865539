// About.js
import React from 'react';
import { Link } from 'react-router-dom';
import './css/About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">À propos d'Imaginia.</h1>
      <div className="about-content">
        <h3 className='about-subtitle'>Imaginia est un outil de création d'images conçu pour être simple et accessible :</h3>
        <ul className="about-list">
          <li>Permettre à chacun, quelles que soient ses compétences de donner vie à ses idées.</li>
          <li>Facile à utiliser : il suffit de décrire l'image souhaitée, et l'IA s'occupe du reste.</li>
          <li>Tarifs conçus pour être abordables, rendant la technologie accessible au plus grand nombre.</li>
          </ul>
          {/* <p className='about-text'>Imaginia convient aux créateurs occasionnels comme aux utilisateurs intensifs. Pour échanger des idées, partager des créations et obtenir de l'aide, un serveur Discord est disponible pour la communauté. */}
          {/* Que l'on soit artiste, étudiant, professionnel ou simplement curieux, Imaginia offre la possibilité d'explorer sa créativité d'une nouvelle façon. C'est l'occasion de transformer ses idées en images uniques, d'expérimenter avec différents styles, et de découvrir le potentiel de l'IA dans l'art, le tout de manière simple et abordable.</p> */}
      </div>
      <p className="about-pricing">
        Découvrez nos <Link to="/pricing">forfaits</Link> et commencez à créer dès aujourd'hui.
      </p>
    </div>
  );
};

export default About;