// Gallery.js
import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getImageUrl, listImages } from './services/apiService';
import { useAuth } from './AuthContext';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';

import './css/Gallery.css';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchImages = async () => {
      if (!user || !user.username) {
        console.log("Utilisateur non connecté ou username non disponible");
        return;
      }

      try {
        console.log("Tentative de récupération des images pour l'utilisateur:", user.username);
        const data = await listImages(user.username);
        console.log("Données reçues de l'API:", data);
        if (Array.isArray(data)) {
          const formattedImages = data.map(file => {
            const originalName = file.name;
            const thumbName = `thumb_${file.name.slice(6).replace('.png', '.webp')}`;
            return {
              id: file.id,
              src: getImageUrl(`data/${originalName}`),
              thumb: getImageUrl(`data/${thumbName}`),
              alt: file.prompt,
              date: new Date(file.date).toLocaleDateString(),
              prompt: file.prompt,
              starred: file.starred
            };
          });
          setImages(formattedImages);
        } else {
          console.error("Format de données incorrect reçu de l'API");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des images:", error);
      }
    };

    fetchImages();
  }, [user]);

  if (!user) {
    return <p>Veuillez vous connecter pour voir votre galerie.</p>;
  }

  return (
    <div className="gallery-wrapper">
      <LightGallery
        elementClassNames="gallery-container"
        plugins={[lgThumbnail, lgZoom]}
        thumbnail={true}
      >
        {images.map((image) => (
          <a 
            href={image.src} 
            key={image.id}
            className="gallery-item"
            data-thumb={image.thumb}
            data-sub-html={`<h4>${image.prompt}</h4><p>Date: ${image.date}</p>`}
          >
            <LazyLoadImage
              src={image.thumb}
              alt={image.alt}
              effect="opacity"
              threshold={300}
              wrapperClassName="lazy-load-image-wrapper"
            />
            <div className="image-overlay">
              <p>{image.prompt}</p>
              <p>{image.date}</p>
              <span className="star">{image.starred ? '★' : '☆'}</span>
            </div>
          </a>
        ))}
      </LightGallery>
      {images.length === 0 && <p>Aucune image à afficher</p>}
    </div>
  );
};

export default Gallery;