// src/components/ImageContainer.js
import React from 'react';
import './ImageContainer.css';

const ImageContainer = ({ imageUrl }) => {
  return (
    <div className="image-container">
      {imageUrl && (
        <img 
          src={imageUrl} 
          alt="Generated" 
          className="generated-image"
        />
      )}
    </div>
  );
};

export default ImageContainer;