// src/components/ProgressBar.js
import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ percentage, height = '20px', backgroundColor = '#3f3f44', progressColor = '#44447E' }) => {
  return (
    <div style={{ 
      width: '60%', 
      backgroundColor: backgroundColor, 
      borderRadius: '5px', 
      margin: '10px 0' 
    }}>
      <div
        style={{
          width: `${percentage}%`,
          backgroundColor: progressColor,
          height: height,
          borderRadius: '5px',
          transition: 'width 0.5s ease-in-out',
        }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  progressColor: PropTypes.string,
};

export default ProgressBar;