// src/services/apiService.js

const API_ENDPOINT = process.env.REACT_APP_API_URL;

export const checkCredits = async (user) => {
  const response = await fetch(`${API_ENDPOINT}/check_credits`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: user })
  });
  if (!response.ok) {
    throw new Error("Vous n'avez plus de crédits.");
  }
  const data = await response.json();
  return data;
};

export const traduction = async (texte) => {
  const response = await fetch(`${API_ENDPOINT}/traduction`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ texte: texte })
  });

  if (!response.ok) {
    throw new Error(`Traduction échouée: ${response.statusText}`);
  }

  const data = await response.json();
  return data.traduction;
};

export const generateImage = async (prompt) => {
  const response = await fetch(`${API_ENDPOINT}/discord_generate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(prompt)
  });

   if (!response.ok) {
      if (response.status === 555) {
        throw new Error('Vous n\'avez plus de crédits.');
      } else {
        throw new Error(`Génération d'image échouée: ${response.statusText}`);
      }
    }

  const data = await response.json();
  return data.job_id;
};

export const checkStatus = async (jobId) => {
  const response = await fetch(`${API_ENDPOINT}/discord_status?job_id=${encodeURIComponent(jobId)}`, {
  });

  if (!response.ok) {
    throw new Error(`Vérification du statut échouée: ${response.statusText}`);
  }

  return await response.json();
};

export const saveGeneratedImage = async (imageData, username, alt) => {
  const response = await fetch(`${API_ENDPOINT}/save-image`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ image_data: imageData, user: username, alt: alt })
  });

  if (!response.ok) {
    throw new Error(`Sauvegarde de l'image échouée: ${response.statusText}`);
  }

  const data = await response.json();
  return {
    originalUrl: data.originalUrl,
    thumbnailUrl: data.thumbnailUrl,
    alt: data.alt
  };
};

export const getImageUrl = (imagePath) => `${API_ENDPOINT}/${imagePath}`;

export const listImages = async (user) => {
  const response = await fetch(`${API_ENDPOINT}/list-images?username=${encodeURIComponent(user)}`);
  if (!response.ok) {
    throw new Error('Erreur lors de la récupération de la liste des images');
  }
  return await response.json();
};